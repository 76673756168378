import type { NextPage } from 'next';
import { NextSeo } from 'next-seo';
import { t } from '@lingui/macro';
import { withLocale } from '@/locales';
import { Button } from '@/components';
import { useOG } from '@/hooks';

const NotFound: NextPage = () => {
  const { openGraph } = useOG(t`Page Not Found`);

  return (
    <>
      <NextSeo title={t`Page Not Found`} openGraph={{ ...openGraph }} />
      <div className="min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main className="text-center sm:flex sm:text-left">
            <p className="text-4xl font-bold tracking-tight text-primary-600 sm:text-5xl">404</p>
            <div className="sm:ml-6">
              <div className="sm:pl-6">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">{t`Page Not Found`}</h1>
                <p className="mt-1 text-base text-gray-500">{t`Please check the URL in the address bar and try again.`}</p>
              </div>
              <div className="mt-10 flex space-x-3 justify-center sm:border-l sm:border-transparent sm:pl-6 sm:justify-start">
                <Button href="/">{t`Go home`}</Button>
                <Button href="https://help.videocom.com/en/" bordered>{t`Contact us`}</Button>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export const getStaticProps = withLocale('static');

export default NotFound;
